<template>
  <div>
    <div class="table-responsive">
      <table class="table borderless text-center">
        <thead>
          <tr>
            <td scope="col" id="header" class="text-white p-2">
              <b>{{ data.name }}: </b>
              <span id="header-value"
                ><b>{{ data.value }} <span v-if="$route.params.stationName == 'sodrazica' && data.unit == 'm/s'">km/h</span><span v-else>{{ data.unit }}</span></b></span
              >
            </td>
          </tr>
        </thead>
      </table>
      <div>
        <highcharts
          class="custom"
          :options="chartOptions"
          style="height: 300px"
          ref="highcharts"
        ></highcharts>
      </div>
      <table class="table borderless text-center" id="table-details">
        <tbody>
          <tr class="mb-1">
            <td>
              <span id="min">{{ data.minName }}: </span>
              <span class="body-bold">
                <span id="body-value">{{
                  data.minValue
                }}</span>
                 <span v-if="$route.params.stationName == 'sodrazica' && data.unit == 'm/s'"> km/h</span><span v-else>{{ data.unit }}</span>
                <span v-if="data.minTime !== null">
                 ob
                  <span id="body-value">{{
                    data.minTime
                  }}</span>
                </span>
              </span>
            </td>
          </tr>
          <tr class="mb-1">
            <td>
              <span class="body-bold">
                <span v-if="data.maxName !== null">
                  <span id="max">{{ data.maxName }}: </span>
                  <span id="body-value">{{
                    data.maxValue
                  }}</span>
                  <span v-if="$route.params.stationName == 'sodrazica' && data.unit == 'm/s'"> km/h</span><span v-else>{{ data.unit }}</span>
                </span>
                <div v-else class="extend1"></div>
                <span v-if="data.unitH !== null">{{data.unitH}}</span>
                <span v-if="data.maxTime === null"></span>
                <span v-else-if="data.minTime !== null"> ob
                <span id="body-value">{{
                  data.maxTime
                }}</span>
                </span>
                <span v-else></span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="body-bold"
                v-if="data.widgetStatusEnum !== detailsStatusEnum.WIND_SPEED"
              >
                <span id="avg"><span v-if="data.avgValue !== null">{{ data.avgName }}: </span
                ><span v-else><div class="extend"></div></span> </span>
                <span v-if="data.widget == detailsStatusEnum.RAIN">{{
                  data.snow
                }}</span>
                <span v-else-if="data.avgValue !== null"
                  ><span id="body-value">{{
                    data.avgValue
                  }}</span>
                  {{ data.unit }}</span
                >
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { DetailsStatusEnum } from "../../../Enums/index";

export default {
  name: "Details",
  data() {
    return {
      detailsStatusEnum: DetailsStatusEnum,
    };
  },
  props: {
    data: Object, 
    chartData: Object,
    chartOptions: Object
  },
  watch: {
    '$route' () {
      this.$store.dispatch("getDetailsData");
    }
  }
};
</script>

<style scoped>
.borderless td,
.borderless th {
  border: none;
}

#header {
  background-color: rgb(45, 50, 138);
  border-radius: 5px;
  font-size: 1.35rem;
}

#header-value {
  padding: 0.15rem;
  font-size: 1.15rem;
  border-radius: 5px;
  background-color: white;
  color: black;
}

#body-value {
  padding: 0.15rem;
  font-size: 1.15rem;
}

.chart {
  border-radius: 5px;
}

#table-details {
  border-radius: 10px;
  border-collapse: separate;
  border-spacing: 0 10px;
}
#table-details td {
  background-color: rgba(218, 216, 216, 0.863);
}

.extend {
  padding: 12px;
}

.extend1 {
  padding: 13px;
}

#min {
  color: blue;
  font-weight: 700;
  font-size: 1.1rem;
}

#max {
  color: red;
  font-weight: 700;
  font-size: 1.1rem;
}

#avg {
  color: green;
  font-weight: 700;
  font-size: 1.1rem;
}

.body-bold {
  font-weight: 500;
}
</style>